const dashboardRoutes = [
  {
    path: "",
    name: "homePage",
    component: () => import("@/modules/dashboard/Home.vue"),
    meta: {
      layout: "default",
      redirectIfLoggedIn: true
    }
  }
]

export default dashboardRoutes
