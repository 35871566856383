<template>
  <div class="footer_content">
    <div class="flex flex-wrap align-items-center justify-content-center">
      <span
        class="cursor-pointer text-white px-2 md:px-4 py-2 text-xl"
        @click="smoothScroll('#about')"
        >About Us</span
      >
      <span
        class="cursor-pointer text-white px-2 md:px-4 py-2 text-xl"
        @click="smoothScroll('#packages')"
        >Packages</span
      >
      <span
        class="cursor-pointer text-white px-2 md:px-4 py-2 text-xl"
        @click="smoothScroll('#fandb')"
        >F&B</span
      >
      <span
        class="cursor-pointer text-white px-2 md:px-4 py-2 text-xl"
        @click="smoothScroll('#contact')"
        >Contact Us</span
      >
    </div>
    <div class="mt-4">
      <span class="px-2">
        <a href="https://www.facebook.com/starcitysportsclub" target="_blank">
          <Button
            icon="pi pi-facebook"
            class="p-button-rounded p-button-outlined text-white"
          />
        </a>
      </span>
      <span class="px-2">
        <a href="tel:09777377666">
          <Button
            icon="pi pi-phone"
            class="p-button-rounded p-button-outlined text-white"
          />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"
import { useRouter, useRoute } from "vue-router"
import EventBus from "@/libs/AppEventBus"

export default {
  name: "AppFooter",
  components: { Button },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const smoothScroll = (id) => {
      if (route.name === "homePage") {
        EventBus.emit("smooth-scroll", {
          id: id
        })
      } else {
        router.replace({
          name: "homePage"
        })
      }
    }
    return {
      smoothScroll
    }
  }
}
</script>
<style>
.footer_content {
  width: 100%;
  background: #2f3081;
  padding: 80px 0px;
  text-align: center;
}

.footer_section {
  padding: 20px 0px;
  text-align: center;
  background: #2f3081;
}
</style>
