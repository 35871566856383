<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()" />
      <span>StarCity Sports Club</span>
    </router-link>
    <!-- <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars" />
    </button> -->

    <button
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true
      }"
      class="p-link layout-topbar-menu-button layout-topbar-button"
    >
      <i class="pi pi-ellipsis-v" />
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top mx-auto">
      <li>
        <Button
          type="button"
          label="About Us"
          style="width: auto"
          class="p-button-text lg:text-xl"
          @click="smoothScroll('#about')"
        />
      </li>
      <li>
        <Button
          type="button"
          label="Packages"
          style="width: auto"
          class="p-button-text lg:text-xl"
          @click="smoothScroll('#packages')"
        />
      </li>
      <li>
        <Button
          type="button"
          label="F&B"
          style="width: auto"
          class="p-button-text lg:text-xl"
          @click="smoothScroll('#fandb')"
        />
      </li>
      <li>
        <Button
          type="button"
          label="Contact Us"
          style="width: auto"
          class="p-button-text lg:text-xl"
          @click="smoothScroll('#contact')"
        />
      </li>

      <li v-if="authStore.isAuth" class="block lg:hidden">
        <Menu ref="menu" :model="profileMenuItems" :popup="true" />
        <Button
          type="button"
          label="Profile"
          style="width: auto"
          class="p-button-text lg:text-xl"
          @click="toggleMenu"
        />
      </li>
      <li v-else class="block lg:hidden">
        <router-link :to="{ name: 'login' }">
          <Button
            type="button"
            label="Login"
            style="width: auto"
            class="p-button-text lg:text-xl"
          />
        </router-link>
      </li>
    </ul>
    <div class="hidden lg:block">
      <div v-if="authStore.isAuth">
        <Menu ref="menu" :model="profileMenuItems" :popup="true" />
        <Button
          type="button"
          label="Profile"
          style="width: auto"
          class="p-button-primary lg:text-xl"
          @click="toggleMenu"
        />
      </div>
      <div v-else>
        <router-link :to="{ name: 'login' }">
          <Button
            type="button"
            label="Login"
            style="width: auto"
            class="p-button-primary lg:text-xl"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"
import Menu from "primevue/menu"

import { ref, onMounted, defineComponent } from "vue"
import { useLocaleStore } from "@/store/localeStore"
import { useAuthStore } from "@/modules/auth/authStore"

import EventBus from "@/libs/AppEventBus"
import { useRouter, useRoute } from "vue-router"

export default defineComponent({
  components: { Button, Menu },
  setup() {
    const localeStore = useLocaleStore()
    const authStore = useAuthStore()
    const router = useRouter()
    const route = useRoute()
    const profileMenuItems = ref([
      {
        label: "User Profile",
        icon: "pi pi-user",
        command: () => {
          router.push({ name: "accountinfo" })
        }
      },
      // {
      //   label: "Edit Profile",
      //   icon: "pi pi-user-edit",
      //   command: () => {
      //     router.push({ name: "editprofile" })
      //   }
      // },
      {
        label: "Change Password",
        icon: "pi pi-unlock",
        command: () => {
          router.push({ name: "changepwd" })
        }
      },
      {
        separator: true
      },
      {
        label: "Logout",
        icon: "pi pi-sign-out",
        command: () => {
          userLogout()
        }
      }
    ])

    onMounted(() => {})

    const getFlagUrl = () => {
      return localeStore.getCurrentLanguage === "mm"
        ? require("@/assets/flags/mm.png")
        : require("@/assets/flags/en.png")
    }

    const userLogout = () => {
      authStore.logout()
      router.replace({ name: "login" })
    }

    const smoothScroll = (id) => {
      if (route.name === "homePage") {
        EventBus.emit("smooth-scroll", {
          id: id
        })
      } else {
        router.replace({
          name: "homePage"
        })
      }
    }

    return {
      localeStore,
      profileMenuItems,
      authStore,
      getFlagUrl,
      smoothScroll
    }
  },
  data() {
    return {
      languageMenuItems: [
        {
          label: "Myanmar",
          image: require("@/assets/flags/mm.png"),
          code: "mm",
          command: (event) => {
            this.changeLocale(event.item.code)
          }
        },
        {
          label: "English",
          image: require("@/assets/flags/en.png"),
          code: "en",
          command: (event) => {
            this.changeLocale(event.item.code)
          }
        }
      ]
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme
    }
  },
  methods: {
    onMenuToggle(event) {
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit("menu-toggle", event)
    },
    onTopbarMenuToggle(event) {
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit("topbar-menu-toggle", event)
    },
    topbarImage() {
      return require("@/assets/images/SCSC_ColorLogoWithoutBackground_2022VF.png")
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event)
    },
    toggleLanguageMenu(event) {
      this.$refs.languageMenu.toggle(event)
    },
    onContextRightClick(event) {
      this.$refs.contextMenu.show(event)
    },
    changeTheme(event, theme, dark) {
      EventBus.emit("theme-change", { theme: theme, dark: dark })
      event.preventDefault()
    },
    changeLocale(locale) {
      this.localeStore.setLanguage(locale)
      this.$i18n.locale = locale
    }
  }
})
</script>
<style scope>
.p-button-label {
  font-weight: normal !important;
}
</style>
