const familyMemberRoutes = [
  {
    path: "/family-member-information",
    name: "familyMemberInformation",
    component: () => import("@/modules/familymember/FamilyMember"),
    meta: { layout: "default" }
  },
  {
    path: "/family-member-information/:memberId/create",
    name: "CreateFamilyMember",
    component: () => import("@/modules/familymember/entry/CreateFamilyMember"),
    meta: { layout: "default" }
  },
  {
    path: "/family-member-information/:memberId/edit/:id",
    name: "EditFamilyMember",
    component: () => import("@/modules/familymember/entry/EditFamilyMember"),
    meta: { layout: "default" }
  }
]

export default familyMemberRoutes
