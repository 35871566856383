import "@/plugins/global-css"
import { defineCustomElements } from "@ionic/pwa-elements/loader"

import { app } from "@/plugins/main-app"
import "@/plugins/abality"
import "@/plugins/confirmation-service"
import "@/plugins/global-components"
import "@/plugins/i18n"
import "@/plugins/pinia"
import "@/plugins/moment"
import "@/plugins/primevue"
import "@/plugins/router"
import "@/plugins/toast-service"
import "@/plugins/theme"

app.mount("#app")

defineCustomElements(window)
