<template>
  <div>
    <img
      class="w-full h-full"
      :src="require('@/assets/images/new-banner.png')"
      alt="banner"
      style="object-fit: cover"
    />
  </div>
</template>

<script>
export default {
  name: "AppBanner"
}
</script>
<style></style>
