<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar />
    <div style="height: 6rem" class="w-full"></div>
    <AppBanner v-if="$route.meta.banner" />
    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue"
import AppFooter from "./AppFooter.vue"
import AppBanner from "./AppBanner.vue"

export default {
  components: {
    AppTopBar: AppTopBar,
    AppFooter: AppFooter,
    AppBanner: AppBanner
  },
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false
    }
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false
        }
      ]
    }
  },
  watch: {
    $route() {
      this.menuActive = false
      // this.$toast.removeAllGroups()
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden")
    else this.removeClass(document.body, "body-overflow-hidden")
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }

      this.menuClick = false
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className)
      else element.className += " " + className
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className)
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        )
    },
    isDesktop() {
      return window.innerWidth >= 992
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive
        else if (this.layoutMode === "overlay") return this.overlayMenuActive
      }

      return true
    }
  }
}
</script>

<style lang="scss">
@import "./App.scss";
</style>
