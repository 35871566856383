const booknowRoutes = [
  {
    path: "/membership-packages",
    name: "subscriptionList",
    component: () => import("@/modules/booknow/subscription/Subscription"),
    meta: { redirectIfLoggedIn: true, layout: "default" }
  },
  {
    path: "/prepaid-packages",
    name: "prepaidList",
    component: () => import("@/modules/booknow/prepaid/Prepaid"),
    meta: { redirectIfLoggedIn: true, layout: "default" }
  },
  {
    path: "/membership-package/:id",
    name: "subscriptionDetail",
    component: () =>
      import("@/modules/booknow/subscription/SubscriptionDetail"),
    meta: { redirectIfLoggedIn: true, layout: "default" }
  },
  {
    path: "/prepaid-package/:id",
    name: "prepaidDetail",
    component: () => import("@/modules/booknow/prepaid/PrepaidDetail"),
    meta: { redirectIfLoggedIn: true, layout: "default" }
  }
]

export default booknowRoutes
