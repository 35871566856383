import { defineStore } from "pinia"
import Cookies from "js-cookie"
import { authService } from "./authService"
import { familyMemberService } from "../familymember/familyMemberService"

export const useAuthStore = defineStore({
  id: "useAuthStore",
  state: () => ({
    token: Cookies.get("userToken") || null,
    userData: Cookies.get("userData") || null,
    loginResponse: null,
    updateResponse: null,
    resetResponse: null,
    userInfo: Cookies.get("userInfo") || null,
    userProfile: null,
    updateProfile: null,
    familyMemberList: []
  }),

  getters: {
    getToken(state) {
      return state.token
    },
    getUserData(state) {
      return state.userData
    },
    getLoginResponse(state) {
      return state.loginResponse
    },
    isAuth(state) {
      return state.token ? true : false
    },
    getUserInfo(state) {
      return state.userInfo
    },
    getUpdateResponse(state) {
      return state.updateResponse
    },
    getResetResponse(state) {
      return state.resetResponse
    },
    getUserProfileResponse(state) {
      return state.userProfile
    },
    getUserUpdateResponse(state) {
      return state.updateProfile
    }
  },

  actions: {
    async login(params) {
      const response = await authService.login(params)
      this.loginResponse = response
      if (response) {
        Cookies.set("userToken", response.data.access_token)
        Cookies.set("userData", JSON.stringify(response.data.user))
        this.token = response.data.access_token
        this.userData = response.data.user
      }
    },
    logout() {
      this.token = null
      this.userData = null
      Cookies.remove("userToken")
      Cookies.remove("userData")
      // authService.logout()
    },
    async fetchUserInfo(userID) {
      const resp = await authService.userInfo(userID)
      if (resp) {
        Cookies.set("userInfo", JSON.stringify(resp.data))
        this.userInfo = resp.data
        // console.log(this.userInfo)
      }
    },
    async passwordUpdate(params) {
      const response = await authService.passwordUpdate(params)
      this.updateResponse = response
    },
    async passwordReset(params) {
      const response = await authService.passwordReset(params)
      this.resetResponse = response
    },
    async fetchUserProfile() {
      const response = await familyMemberService.getUserProfile()
      this.userProfile = response
    },
    async updateUserProfile(params) {
      const response = await familyMemberService.updateUserProfile(params)
      this.updateProfile = response
    },
    async fetchFamilyMember(memberId) {
      const response = await familyMemberService.getFamilyMemberList(memberId)
      this.familyMemberList = response
      return response
    }
  }
})
