const authRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("./login/Login"),
    meta: { redirectIfLoggedIn: true, layout: "default", banner: true }
  },
  {
    path: "/",
    name: "logout",
    component: () => import("@/modules/dashboard/Home.vue"),
    meta: { isLoggedIn: false, layout: "default" }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./signup/SignUp"),
    meta: { redirectIfLoggedIn: true, layout: "default", banner: true }
  },
  {
    path: "/update-password",
    name: "changepwd",
    component: () => import("./signup/ChangePwd"),
    meta: { layout: "default", banner: true }
  },
  {
    path: "/reset-password",
    name: "resetpwd",
    component: () => import("./signup/ResetPassword"),
    meta: { redirectIfLoggedIn: true, layout: "default", banner: true }
  },
  {
    path: "/account-information",
    name: "accountinfo",
    component: () => import("./accInfo/AccountInfo"),
    meta: { layout: "default" }
  },
  // {
  //   path: "/edit-profile",
  //   name: "editprofile",
  //   component: () => import("./signup/EditProfile"),
  //   meta: { layout: "default" }
  // },
  {
    path: "/accountinfo/all-packages",
    name: "allpackages",
    component: () => import("./accInfo/AllPackages"),
    meta: { layout: "default" }
  },
  {
    path: "/package/:name",
    name: "package",
    component: () => import("./packagehistory/PackageHistory"),
    meta: { layout: "default" }
  }
]

export default authRoutes
