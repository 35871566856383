import api from "@/libs/axios"
const endpoints = {
  useProfile: "/scsc/profile",
  family: "/scsc/sync-family-members",
  nrcState: "/master/nrc/states",
  nrcTownship: "/master/nrc/township",
  nrcType: "/master/nrc/type",
  nrc: "/master/nrc",
  member: "/scsc/members"
}
const familyMemberService = {
  getUserProfile: async () => {
    try {
      const result = await api.get(`${endpoints.useProfile}`)
      return result.data
    } catch {
      return null
    }
  },

  getFamilyMemberList: async (memberId) => {
    try {
      const result = await api.get(
        `${endpoints.member}/${memberId}/family-member`
      )
      return result.data
    } catch (error) {
      return error
    }
  },

  updateUserProfile: async (params) => {
    const result = await api.put(`${endpoints.family}`, params)
    return result.data
  },

  getNRCState: async (params) => {
    try {
      const result = await api.get(`${endpoints.nrcState}`, params)
      return result.data
    } catch {
      return null
    }
  },

  getNRCTownship: async (params) => {
    try {
      // const result = await api.get(
      //   `${endpoints.nrcTownship}?nrc_state_id=${id}`
      // )
      const result = await api.get(`${endpoints.nrcTownship}`, params)
      return result.data
    } catch {
      return null
    }
  },

  getNRCType: async (params) => {
    try {
      const result = await api.get(`${endpoints.nrcType}`, params)
      return result.data
    } catch {
      return null
    }
  },

  getNRC: async (params) => {
    try {
      const result = await api.get(`${endpoints.nrc}`, params)
      return result.data
    } catch {
      return null
    }
  },

  indexFamilyMember: async (memberId) => {
    try {
      const result = await api.get(
        `${endpoints.member}/${memberId}/family-member`
      )
      return result.data
    } catch (error) {
      return error
    }
  },
  showFamilyMember: async (memberId, id) => {
    try {
      const response = await api.get(
        `${endpoints.member}/${memberId}/family-member/${id}`
      )
      return response.data
    } catch (error) {
      return error
    }
  },

  createFamilyMember: async (memberId, data) => {
    try {
      const result = await api.post(
        `${endpoints.member}/${memberId}/family-member`,
        data
      )
      return result.data
    } catch (error) {
      return error
    }
  },

  editFamilyMember: async (memberId, id, request) => {
    try {
      const result = await api.post(
        `${endpoints.member}/${memberId}/family-member/${id}`,
        request
      )
      return result.data
    } catch (error) {
      return error
    }
  },

  removeFamilyMember: async (memberId, id) => {
    try {
      const result = await api.delete(
        `${endpoints.member}/${memberId}/family-member/${id}`
      )
      return result.data
    } catch (error) {
      return error
    }
  }
}

export { familyMemberService }
