import api from "@/libs/axios"
// import Cookies from "js-cookie"
const endpoints = {
  login: "/scsc/auth/login",
  userInfo: "/scsc/members",
  updatePwd: "/scsc/auth/update-password",
  resetPwd: "/auth/reset-password"
}
const authService = {
  login: async (params) => {
    const result = await api.post(endpoints.login, params)
    return result.data
  },
  userInfo: async (userID) => {
    try {
      const result = await api.get(`${endpoints.userInfo}/${userID}`)
      return result.data
    } catch {
      return null
    }
  },

  passwordUpdate: async (params) => {
    const result = await api.put(endpoints.updatePwd, params)
    return result.data
  },

  passwordReset: async (params) => {
    const result = await api.put(endpoints.resetPwd, params)
    return result.data
  }
}
export { authService }
